.login
{
    background-image: url("./images/blue-map.jpeg");
   
    height: 100%!important;
    width: 100%!important;
    top: 0!important;
    .login-box
    {
        text-align: center;
    }
    .error
    {
        color: red;
    }
}