.users{
    .card-title{
        float: right !important;
    }
    .vminwidth
    {
        min-width: 150px;
    }
}
.addmarquee{
    .validation{
        color: red;
        font-size: 12px;
    }
}
.updateteam
{
    .validation{
        color: red;
        font-size: 12px;
    }
}