.university{
    /*.card-title{
        float: right !important;
    }*/
    .vminwidth
    {
        min-width: 150px;
    }
    .fleft{
        float: left;
    }
    .labelstyle{
        display: inline-block;
        
    }
    .fcenter{
        text-align: center;
    }
    .fright{
        text-align: right;
    }
    #searchuniversitytable{
        display: none;
    }
}
.adduniversity{
    .validation{
        color: red;
        font-size: 12px;
    }
}