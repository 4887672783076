.users{
    .card-title{
        float: right !important;
    }
    .vminwidth
    {
        min-width: 150px;
    }
}
.addmarquee{
    .validation{
        color: red;
        font-size: 12px;
    }
}
.viewcandidate{
  .validation{
    color: red;
  }
    .logstyle{
        min-height: 30px;
        border-radius: 3px;
        font-family: Arial;
        font-size: 14px;
        line-height: 1.5;
        color: #797979;
    }
    .dialogbox .body {
        position: relative;
        /*max-width: 300px;*/
        height: auto;
        margin: 20px 10px;
        padding: 5px;
        background-color: #DADADA;
        border-radius: 3px;
        border: 5px solid #ccc;
      }
      
      .body .message {
        min-height: 30px;
        border-radius: 3px;
        font-family: Arial;
        font-size: 16px;
        line-height: 1.5;
        color: #000000;
      }
      .tip {
        width: 0px;
        height: 0px;
        position: absolute;
        background: transparent;
        border: 10px solid #ccc;
      }
      
      .tip-up {
        top: -25px; /* Same as body margin top + border */
        left: 10px;
        border-right-color: transparent;
        border-left-color: transparent;
        border-top-color: transparent;
      }
      
}