body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

  .vminwidth {
    min-width: 150px;
  }
  #successrate{
    text-align: center;
  }
  #successval{
    font-size: 26px;
    font-weight: bold;
  }
  #inprogress,#approved,#awaiting,#duration{
    display: inline-block;
  }
  .info{
    color: white !important;
    text-decoration: none !important;
    cursor: pointer !important;
  }
  .info a{
    color: white !important;
    text-decoration: none !important;
    cursor: pointer !important;
  }
  .loader
{
	position: fixed;
            left: 0px;
            top: 0px;
            width: 100%;
            height: 100%;
            z-index: 9999;
            background: url('images/loader.gif') 50% 50% no-repeat rgb(249,249,249);
            opacity: .8;
}
.horizontal-slider {
  width: 100%;
  max-width: 500px;
  height: 100vh;
  margin: auto;
}
.example-thumb {
  cursor: pointer;
  position: absolute;
  z-index: 100;
  background: #ffffff;
  border: 5px solid #3774ff;
  border-radius: 100%;
  display: block;
  box-shadow: 0 0 2px 0 rgb(0 0 0 / 44%);
}
.example-thumb.active {
  background-color: grey;
}
.example-track {
  position: relative;
  background: #ddd;
}
.example-track.example-track-0 {
  background: #83a9ff;
}
.horizontal-slider .example-track {
  top: 20px;
  height: 4px;
}
.horizontal-slider .example-thumb {
  top: 12px;
  width: 10px;
  outline: none;
  height: 10px;
  line-height: 38px;
}
#moreinfovisitor,#moreinfosuper{
background-color: rgba(0, 0, 0, .1);
color: rgba(255, 255, 255, .8);
display: block;
padding: 3px 0;
position: relative;
text-align: center;
text-decoration: none;
z-index: 10;
}